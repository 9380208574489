import React from "react";
import Typography from "@mui/material/Typography";

import cssStyles from "./About.module.scss";

export function getAboutText() {
  return (
    <div className={cssStyles.about}>
      <Typography variant="body1" paragraph>
        At Balti Kitchen we offer creative Balti, Bangladeshi, Halal, Indian
        dishes delivered right to your door. Our menu contains our perfected
        dishes created by our team of chefs who demand only the best. Browse our
        delicious menu of Balti, Bangladeshi, Halal, Indian dishes and have it
        delivered straight to your door. Balti Kitchen can make the next night
        in one to remember!
      </Typography>

      <Typography variant="body1" paragraph>
        Balti Kitchen is based in the Battersea area of London, and is very
        close to Clapham Junction train station, York Gardens and Falcon Park.
      </Typography>
    </div>
  );
}

export function getAdditionalAboutText() {
  return null;
}

export function getAccolades() {
  return [];
}

export function getGalleryMenuImages() {
  return [];
}

export function getGalleryRestaurantImages() {
  return [];
}
